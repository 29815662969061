<template>
  <section id="new-connection">
    <b-row class="match-height">
      <b-col cols="12">
        <b-card no-body>
          <b-card-header>
            <b-card-title>
              Подключение Yandex Direct
            </b-card-title>
          </b-card-header>
          <b-card-body>
            <b-overlay
              v-if="directTokens"
              :show="saveLoading"
              rounded="sm"
            >
              <b-col cols="4">
                <span
                  v-if="loginOptions.length != 0"
                  class="mb-5"
                >
                  <b>
                    Найдено {{ loginOptions.length }} логинов
                  </b>
                </span>
                <v-select
                  id="item-name-1222"
                  v-model="login"
                  :options="loginOptions"
                  class="mb-1 mt-1"
                  label="text"
                  :loading="accountsLoading"
                  placeholder="Найдите необходимый логин..."
                >
                  <template #no-options="{ search, searching, loading }">
                    Не найдено.
                  </template>
                </v-select>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="saveIntegration()"
                >
                  Сохранить выбор
                </b-button>
              </b-col>
            </b-overlay>
            <b-col
              v-else
              cols="12"
            >
              <h5>Выберите ваш тип аккаунта</h5>
              <b-form-radio
                v-model="accountType"
                name="some-radio9"
                value="agency"
                class="custom-control-primary"
              >
                Агентство
              </b-form-radio>
              <b-form-radio
                v-model="accountType"
                name="some-radio9"
                value="clients"
                class="custom-control-primary"
              >
                Прямой рекламодатель
              </b-form-radio>
              <br>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :disabled="accountType == null"
                variant="outline-primary"
                @click="redirectYandexOAuth()"
              >
                <b-spinner
                  v-if="showRedirect"
                  small
                />
                Перенаправить на oauth.yandex.ru
              </b-button>
            </b-col>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>
<script>
import {
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BOverlay,
  BSpinner,
  BFormRadio,
  BCardTitle,
  BCol,
  BRow,
  VBToggle, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import OpenIndicator from '@core/components/vue-select/OpenIndicator.vue'

vSelect.props.components.default = () => ({ OpenIndicator })

const YANDEX_DIRECT_AUTH_URL = 'https://oauth.yandex.ru/authorize?response_type=code&force_confirm=true'

export default {
  components: {
    BCard,
    BButton,
    vSelect,
    BCardHeader,
    BCardBody,
    BFormRadio,
    BOverlay,
    BSpinner,
    BCardTitle,
    BRow,
    BCol,
  },
  directives: { 'b-toggle': VBToggle, 'b-tooltip': VBTooltip, Ripple },
  props: {
    connectionId: [String, Number],
    directTokens: {
      type: [Object],
      required: false,
    },
  },
  data() {
    return {
      showRedirect: false,
      accountType: null,
      loginOptions: [],
      login: null,
      accountsLoading: false,
      saveLoading: false,
    }
  },
  computed: {
  },
  mounted() {
    if (this.directTokens != null
        && this.directTokens.accessToken != null) {
      this.accountsLoading = true

      const yandexDirectType = localStorage.getItem('yandex_direct_type')

      this.$http.get(`/yandex-direct/clients?token=${this.directTokens.accessToken}&type=${yandexDirectType}`)
        .then(response => {
          const dataForParse = response.data.response.result
          dataForParse.Clients.forEach(element => this.loginOptions.push({
            // TO-DO: NEED refactoring
            value: element.Login,
            text: element.Login,
          }))
          this.accountsLoading = false
        })
    }
  },
  created() {
  },
  methods: {
    redirectYandexOAuth() {
      this.showRedirect = true
      // TO-DO: NEED refactoring
      function removeSpaces(url) {
        return encodeURI(decodeURI(url).replace(/\s+/g, ''))
      }

      localStorage.setItem('yandex_direct_type', this.accountType)

      const redirectUri = encodeURI(`${window.location.origin}/apply-yandex-direct-code`)

      window.location.href = removeSpaces(`${YANDEX_DIRECT_AUTH_URL}
        &client_id=afa7e217cc014728968039a0d968e7a7
        &state=${this.$route.params.connectionId}
        &redirect_uri=${redirectUri}`)
    },
    saveIntegration() {
      const connId = this.$route.params.connectionId

      // TO-DO: NEED refactoring
      const obj = {
        refreshToken: this.directTokens.refreshToken,
        accessToken: this.directTokens.accessToken,
        accessTokenExpirationDate: this.directTokens.accessTokenExpirationDate,
        connectionId: connId,
        type: 'YANDEX_DIRECT',
        info: this.login.value,
      }
      this.saveLoading = true
      this.$http.post('integration/fill', obj)
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.$router.push({
            name: 'new-connection',
            params: { connectionId: connId },
          })
        })
    },
  },
}
</script>

<style scoped>
.no-content {
  margin:0 auto;
}
</style>
